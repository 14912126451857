import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedContent from './components/auth/protectedContent';
import { UnauthorizedPage } from "./pages";
import { UnsubscribePage } from './pages/unsubscribe/unsubscribePage';

const App = () => {
  return <>
    <BrowserRouter>
      <Switch>
        <Route path="/key" render={({ match: { path } }) => (
          <Switch>
            <Route exact path={path + "/:id"} component={ProtectedContent} />
            <Route exact path={path + "/:id/branch/:branch/deptid/:deptid"} component={ProtectedContent} />
            <Route path="*" children={UnauthorizedPage} />
          </Switch>
        )}>
        </Route>
        <Route path="/unsubscribe" render={({ match: { path } }) => (
          <Switch>
            <Route exact path={`${path}/:mailadress`} component={UnsubscribePage} />
            <Route path="*" children={UnauthorizedPage} />
          </Switch>
        )}>
        </Route>

        <Route path="*" children={UnauthorizedPage} />
      </Switch>
    </BrowserRouter>
  </>
}
export default App;

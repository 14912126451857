import { useEffect, useState } from 'react';
import './unsubscribe.css';
import NApiConfig from '../../common/config/napi.config';
import axios from 'axios';
import { MailadressRoutingParam } from '../../common/routing/types';
import { useParams } from 'react-router-dom';
import Routes from '../../common/routing/routes';

export const UnsubscribePage = () => {
    const { mailadress } = useParams<MailadressRoutingParam>();
    const [pageLoading, setPageLoading] = useState(true);
    const [result, setResult] = useState(Number);
    useEffect(() => {

        const loadPage = async () => {


            let response = await axios.get<number>(NApiConfig().url + "NL/emailToBlackListAPI/" + mailadress);

            setResult(response.data);
            setPageLoading(false);

        }
        if (pageLoading)
            loadPage();

    }, [pageLoading])
    return <>
        {result == 1 &&

            <div className="unsubscribe unsubscribe-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center">
                <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                    <h1 className="unsubscribe-title text-white">Abmeldung</h1>
                    <p className="display-2 font-weight-boldest text-white mb-12">Newsletter</p>
                    <p className="font-size-h1 font-weight-boldest text-dark-75">Wir haben Ihre Abmeldung erhalten, Sie brauchen nichts weiter zu tun.</p>
                    <p className="font-size-h1 font-weight-boldest text-dark-75">Vielleicht sehen wir uns woanders wieder?</p>
                    <p className="font-size-h1 font-weight-boldest text-dark-75">Auch auf <a href='https://linkedin.com/company/theadeccogroup'>LinkedIn</a> finden Sie immer interessante Inhalte rund um Themen HR & Recruiting.</p>
                    <p className="font-size-h4 line-height-md">Natürlich können Sie unsere Newsletter-Angebote immer wieder neu abonnieren. </p>
                    <p className="font-size-h5 line-height-md font-weight-boldest text-dark-75"><a href='https://www.adeccogroup.de/impressum/'>Impressum</a> <a href='https://www.adeccogroup.de/datenschutz/'>Datenschutz</a></p>
                </div>
            </div>






        }
        {result == 0 &&
            <div className="unsubscribe unsubscribe-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center">

                <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                    <h1 className="unsubscribe-title text-white">Abmeldung</h1>
                    <p className="display-4 font-weight-boldest text-white mb-12">Newsletter</p>
                    <p className="font-size-h1 font-weight-boldest text-dark-75">Ups! Irgendwas ist schief gelaufen.</p>
                    <p className="font-size-h4 line-height-md">Bitte versuchen Sie es nochmal.</p>
                </div>
            </div>
        }
    </>
}
import './documentViewerCard.css';
import { Document } from "../../../common/navigation/types";
import Axios from "axios";
import apiConfig from "../../../common/config/api.config";
import { useState } from 'react';
import cssIcon from '../../../media/svg/files/css.svg';
import csvIcon from '../../../media/svg/files/csv.svg';
import docIcon from '../../../media/svg/files/doc.svg';
import htmlIcon from '../../../media/svg/files/html.svg';
import javascriptIcon from '../../../media/svg/files/javascript.svg';
import jpgIcon from '../../../media/svg/files/jpg.svg';
import mp4Icon from '../../../media/svg/files/mp4.svg';
import pdfIcon from '../../../media/svg/files/pdf.svg';
import xmlIcon from '../../../media/svg/files/xml.svg';
import zipIcon from '../../../media/svg/files/zip.svg';

export interface Props {
    document: Document;
    subCategory: string;
}

const key = String(localStorage.getItem("key"));

const DocumentViewerCard = (props: Props) => {

    const [downloading, setDownloading] = useState(false);

    let icon = "";

    let fileType = props.document.type.toLocaleLowerCase();
    if (fileType == "css") {
        icon = cssIcon;
    } else if (fileType == "csv") {
        icon = csvIcon;
    } else if (fileType == "doc" || fileType == "docx") {
        icon = docIcon;
    } else if (fileType == "js") {
        icon = javascriptIcon;
    } else if (fileType == "jpg" || fileType == "png" || fileType == "jpeg") {
        icon = jpgIcon;
    } else if (fileType == "mp4") {
        icon = mp4Icon;
    } else if (fileType == "pdf") {
        icon = pdfIcon;
    } else if (fileType == "html") {
        icon = htmlIcon;
    } else if (fileType == "xml") {
        icon = xmlIcon;
    } else if (fileType == "zip" || fileType == "rar") {
        icon = zipIcon;
    }

    const startDownload = async (documentItem: Document) => {
        setDownloading(true);
        try {
            const download = await Axios.get(apiConfig().url + "Documents/Download/" + documentItem.documentId + "?" + key, {
                responseType: 'blob'
            });
            const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));
            console.log("downloadUrl", downloadUrl);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', documentItem.name + "." + documentItem.type);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error(`falied to download document ${documentItem.documentId}`, error);
        }
        finally {
            setDownloading(false);
        }
    }

    return <>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
            <div className="card card-custom gutter-b card-stretch">
                <div className="card-header border-0">
                    <h3 className="card-title"></h3>
                    <div className="card-toolbar">
                        <div className="dropdown dropdown-inline" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">

                            {!downloading && <div className="btn btn-clean btn-hover-light-danger btn-lg btn-icon" onClick={() => startDownload(props.document)}>
                                <i className="fas fa-download"></i>
                            </div>}
                            {downloading && <div className="spinner spinner-track spinner-danger mr-15 spinner-dowloading"></div>}
                        </div>
                    </div>

                </div>

                <div className="card-body">
                    <div className="d-flex flex-column align-items-center">
                        <div><b>{props.subCategory}</b></div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="d-flex flex-column align-items-center">
                        <img alt="" className="max-h-65px" src={pdfIcon} onClick={() => startDownload(props.document)} />
                        <span className="text-dark-75 font-weight-bold mt-15 font-size-lg">{props.document.name}</span>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default DocumentViewerCard;
import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../components/navigation/breadcrumb';
import apiConfig from '../../common/config/api.config';
import Axios from 'axios';

export interface Props {
    title: string;
    text: string;
    brand: string;
}

const ContentPage = (props: Props) => {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const documentNameAGH = 'Adecco-Lohnabrechnung.mp4';
    const documentNameDIS = 'DISAG_Akkodis-Lohnabrechnung.mp4';


    const key = localStorage.getItem('key');

    useEffect(() => {
        const fetchVideoUrl = async () => {
            if (!key) {
                setError('Kein gültiger Schlüssel gefunden. Bitte melden Sie sich erneut an.');
                setLoading(false);
                return;
            }

            try {

                if (props.brand == "dis" || props.brand == "akkodis") {
                    const response = await Axios.get(`${apiConfig().url}Documents/GetVideoUrl/${documentNameDIS}?key=${encodeURIComponent(key)}`);
                    setVideoUrl(response.data.url);
                }
                else {
                    const response = await Axios.get(`${apiConfig().url}Documents/GetVideoUrl/${documentNameAGH}?key=${encodeURIComponent(key)}`);
                    setVideoUrl(response.data.url);

                }
            } catch (err) {
                setError('Fehler beim Abrufen der Video-URL.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchVideoUrl();
    }, [documentNameDIS, documentNameAGH, key]);

    return (
        <div className="content d-flex flex-column flex-column-fluid">
            <Breadcrumb title={props.title} text={props.text}></Breadcrumb>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    <div className="row">
                        {error && (
                            <div className="col-md-12">
                                <p>{error}</p>
                            </div>
                        )}
                        {loading && (
                            <div className="col-md-12">
                                <p>Video wird geladen...</p>
                            </div>
                        )}
                        {!loading && videoUrl && (
                            <div className="col-md-4">
                                <div className="video-tile">
                                    <video width="100%" height="auto" controls>
                                        <source src={videoUrl} type="video/mp4" />
                                        Ihr Browser unterstützt das Video-Tag nicht.
                                    </video>
                                    <div className="video-description">
                                        <h5>Erklärvideo Lohnabrechnung</h5>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Weitere Inhalte */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentPage;

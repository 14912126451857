import React, { useEffect, useState } from 'react';

import { Category, DepartmentsList } from '../../common/navigation/types';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeInOut, fadeInOutTransition } from '../../common/animations';
import DocumentNavigationItem from './documentNavigationItem';

export interface Props {
    logo: string;
    aside: string;
    department: DepartmentsList | undefined;
}

const Navigation = (props: Props) => {
    const [isActive, setActive] = useState([false]);
    const [selectedCategory, setSelectedCategory] = useState<Category>();

    useEffect(() => {
        props.department?.departments.forEach((department) => {
            setActive(isActive => [...isActive, false]);
        });
    }, []);

    return <>
        <div className={`aside aside-left aside-fixed d-flex flex-column flex-row-auto aside-${props.aside}`}>
            <div className="brand flex-column-auto" id="kt_brand">
                <a href="#" className="brand-logo">
                    <img alt="Logo" src={props.logo} />
                </a>
            </div>

            <div className="aside-menu-wrapper flex-column-fluid">
                <div id="kt_aside_menu" className="aside-menu my-4" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
                    <ul className="menu-nav">
                        <li className={`menu-item ${selectedCategory === undefined && 'menu-item-active'}`} onClick={() => setSelectedCategory(undefined)} aria-haspopup="true">
                            <Link to="/welcome" className="menu-link">
                                <span className="svg-icon menu-icon">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
                                            <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3" />
                                        </g>
                                    </svg>
                                </span>
                                <span className="menu-text">Willkommen</span>
                            </Link>
                        </li>
                        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
                            <motion.div
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={fadeInOut}
                                transition={fadeInOutTransition}
                            >
                                <div className="aside-menu min-h-lg-800px" data-menu-vertical="1" data-menu-scroll="1">
                                    <ul className="menu-nav">
                                        <li className="menu-section">
                                            <h4 className="menu-text">Dokumente</h4>
                                            <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
                                        </li>
                                        {props.department?.departments.map((department, index) => {
                                            return <DocumentNavigationItem key={index} department={department} open={isActive[index]} selectedCategory={selectedCategory} onClick={() =>
                                                setActive(isActive.map((item, id) =>
                                                    id === index ? !isActive[index] : item))
                                            } icon={
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000"></path>
                                                        <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3"></path>
                                                    </g>
                                                </svg>
                                            }
                                                onCategoryClick={(category) => setSelectedCategory(category)} />
                                        })}
                                    </ul>
                                </div>
                            </motion.div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
}

export default Navigation;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DepartmentsList } from '../../common/navigation/types';
import { CIdRoutingParam } from '../../common/routing/types';
import Breadcrumb from '../../components/navigation/breadcrumb';
import DocumentViewerCard from '../../components/widgets/documentViewerCard/documentViewerCard'

export interface Props {
    title: string;
    department: DepartmentsList | undefined;
}

const DocumentPage = (props: Props) => {

    const { cid, did } = useParams<CIdRoutingParam>();
    const [items, setItems] = useState<Array<string>>([]);

    const department = props.department?.departments.find(department => department.departmentId == did);
    const category = department?.categories.find(category => category.categoryId == cid);
    const documents = category?.documents;

    useEffect(() => {
        let tmpItems = new Array<string>();

        props.department?.departments.forEach(department => {
            if (department.departmentId == did) {
                tmpItems.push(department.name);
                department.categories.forEach(category => {
                    if (category.categoryId == cid) {
                        tmpItems.push(category.name);
                    }
                });
            }
        });
        setItems(tmpItems);
    }, [])

    return <>
        <div className="content d-flex flex-column flex-column-fluid">
            <Breadcrumb title={props.title} items={items}></Breadcrumb>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    <div className="row">
                        {documents?.map(document => {
                            return <DocumentViewerCard key={'doc' + document.documentId} document={document} subCategory={document.subCategory ? document.subCategory : ''}></DocumentViewerCard>
                        })}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default DocumentPage;
import React from 'react';

export interface Props {
    title: string;
}

const ImpressumPage = (props: Props) => {
    return <>
        <div className="content d-flex flex-column flex-column-fluid">
            {/* begin::Subheader */}
            <div className="subheader py-2 py-lg-4 subheader-solid">
                <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    {/* begin::Info */}
                    <div className="d-flex align-items-center flex-wrap mr-2">
                        {/* begin::Page Title */}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{props.title}</h5>
                        {/* end::Page Title */}
                        {/* begin::Actions */}

                        {/* end::Actions */}
                    </div>
                    {/* end::Info */}
                    {/* begin::Toolbar */}

                    {/* end::Toolbar */}
                </div>
            </div>
            {/* end::Subheader */}
            {/* begin::Entry */}
            <div className="d-flex flex-column-fluid">
                {/* begin::Container */}
                <div className="container">
                    {/* begin::Dashboard */}
                    <h2>The Adecco Group Germany</h2>
                    <p><strong>Verantwortlicher Herausgeber:</strong></p>
                    <p>Adecco Germany Holding SA &amp; Co. KG<br></br>
                        Fritz-Vomfelde-Straße 26<br></br>
                            40547 Düsseldorf<br></br>
                                Telefon: +49 211 5306530</p>
                    <p>Amtsgericht Düsseldorf: HR A 20821<br></br>
phG: Adecco Germany Holding Management S.A., Zihlmattweg 46, 6005 Luzern/Schweiz<br></br>
Handelsregister Luzern: CHE-115.150.904<br></br>
Geschäftsführer: Elly Piccart und Peter Blersch<br></br>
Verantwortlich für den Internetauftritt: Philipp Schmitz-Waters</p>
                    <p><strong>Haftungshinweis</strong></p>
                    <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                    <p><strong>Zuständige Aufsichtsbehörde&nbsp;</strong></p>
                    <p>Agentur für Arbeit Düsseldorf<br></br>
40180 Düsseldorf<br></br>
                        <a href="http://www.arbeitsagentur.de" target="_blank">www.arbeitsagentur.de</a></p>
                    <p><strong>Berufshaftpflichtversicherung&nbsp;</strong></p>
                    <p>AXA Corporate Solutions<br></br>
2-4, rue Jules Lefébvre / 52 rue<br></br>
d´Amsterdam – 75009 Paris – France<br></br>
Versicherungsnummer:<br></br>
XFR0062093LI</p>
                    <p><strong>Verhaltenskodizes</strong></p>
                    <p>Für uns gelten die Verhaltenskodizes des Bundesverbandes Personaldienstleister e.V., welche in deutscher Sprache unter <a href="http://www.personaldienstleister.de">www.personaldienstleister.de</a> abgerufen werden können sowie über die BAP-Geschäftsstelle erhältlich sind.</p>

                    {/* begin::Row */}
                    <div className="row">
                        <div className="col-lg-6 col-xxl-4">
                            {/* begin::Mixed Widget 1 */}

                            {/* end::Mixed Widget 1 */}
                        </div>
                        <div className="col-lg-6 col-xxl-4">
                            {/* begin::List Widget 9 */}

                            {/* end: List Widget 9 */}
                        </div>
                        <div className="col-lg-6 col-xxl-4">
                            {/* begin::Stats Widget 11 */}

                            {/* end::Stats Widget 11 */}
                            {/* begin::Stats Widget 12 */}

                            {/* end::Stats Widget 12 */}
                        </div>
                        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                            {/* begin::List Widget 1 */}

                            {/* end::List Widget 1 */}
                        </div>
                        <div className="col-xxl-8 order-2 order-xxl-1">
                            {/* begin::Advance Table Widget 2 */}

                            {/* end::Advance Table Widget 2 */}
                        </div>
                        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                            {/* begin::List Widget 3 */}

                            {/* end::List Widget 3 */}
                        </div>
                        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                            {/* begin::List Widget 4 */}

                            {/* end:List Widget 4 */}
                        </div>
                        <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                            {/* begin::List Widget 8 */}

                            {/* end: Card */}
                            {/* end::List Widget 8 */}
                        </div>
                    </div>
                    {/* end::Row */}
                    {/* begin::Row */}
                    <div className="row">
                        <div className="col-lg-4">
                            {/* begin::Mixed Widget 14 */}

                            {/* end::Mixed Widget 14 */}
                        </div>
                        <div className="col-lg-8">
                            {/* begin::Advance Table Widget 4 */}

                            {/* end::Advance Table Widget 4 */}
                        </div>
                    </div>
                    {/* end::Row */}
                    {/* end::Dashboard */}
                </div>
                {/* end::Container */}
            </div>
            {/* end::Entry */}
        </div>
    </>
}

export default ImpressumPage;
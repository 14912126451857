export const fadeInOut = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    }
};

export const listParent = {
    show: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    hide: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
}

export const listItem = {
    show: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    hide: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

export const fadeInOutTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
};


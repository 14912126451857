import React, { useState } from "react";
import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { ApplicationPage, UnauthorizedPage } from "../../pages";
import Spinner from "../spinner/spinner";
import { IdRoutingParam } from '../../common/routing/types';
import Axios from "axios";
import { UserInfoListItem } from "../../common/user/types";
import { DepartmentsList } from "../../common/navigation/types";
import apiConfig from "../../common/config/api.config";
import { stringify } from "querystring";


const ProtectedContent = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<UserInfoListItem>();
    const [dataDepartment, setDataDepartment] = useState<DepartmentsList>();
    const [authorized, setAuthorized] = useState(false);
    const params = useParams<IdRoutingParam>();

    useEffect(() => {
        const authenticate = async () => {

            try {
                const obj = {
                    branch: params.branch,
                    deptid: params.deptid
                };
                console.log("Object", obj);
                let brand = "Adecco";
                if (obj.branch != undefined) {
                    if (obj.branch.indexOf('adecco') != -1) brand = "Adecco";
                    if (obj.branch.indexOf('dis') != -1) brand = "dis";
                    if (obj.branch.indexOf('modis') != -1) brand = "modis";
                    if (obj.branch.indexOf('akkodis') != -1) brand = "modis";
                    if (obj.branch.indexOf('proserv') != -1) brand = "proserv";
                }

                const urlkey = stringify({ key: params.id });
                localStorage.setItem("key", urlkey);

                const url = apiConfig().url;
                if (obj.branch == undefined) {
                    const response = await Axios.get<UserInfoListItem>(url + "Employee/Info?" + urlkey);
                    setData(response.data);
                    const response2 = await Axios.get<DepartmentsList>(url + "Documents/Structure?" + urlkey);
                    setDataDepartment(response2.data);
                }
                else {
                    const response = await Axios.get<UserInfoListItem>(url + "Employee/InfoNL?" + urlkey + "&brand=" + brand + "&deptid=" + obj.deptid);
                    setData(response.data);
                    const response2 = await Axios.get<DepartmentsList>(url + "Documents/StructureNL?" + urlkey + "&brand=" + brand + "&deptid=" + obj.deptid);
                    setDataDepartment(response2.data);
                }


                setAuthorized(true);

            } catch (error) {
                console.error("auth failed", error);
            }
            finally {
                setLoading(false);
            }
        };
        authenticate();

    }, []);

    return <>
        {loading && <Spinner />}
        {!loading && !authorized && <UnauthorizedPage />}
        {!loading && authorized && <ApplicationPage user={data} department={dataDepartment} />}
    </>
}

export default ProtectedContent;
export interface Props {
    title: string;
    text?: string;
    items?: Array<string> | undefined;
}

const Breadcrumb = (props: Props) => {
    return <div className="subheader py-2 py-lg-4 subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-2">
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{props.title} {props.text? <br></br>: "" }{props.text? props.text: ""}</h5>
                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                    {props.items?.map((item, index) => {
                        return (
                            <li key={index} className="breadcrumb-item text-muted">
                                <span className="text-muted">{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    </div>
}

export default Breadcrumb;
import React, { useState } from "react";
import { Route, BrowserRouter, Switch, Redirect, Link } from "react-router-dom";
import Navigation from "../../components/navigation/navigation"
import ContentPage from "./contentPage";
import DocumentPage from "./documentPage";
import { UserInfoListItem } from "../../common/user/types";
import { DepartmentsList } from "../../common/navigation/types";
import adeccoLogo from "../../media/logos/adecco-logo.png";
import modisLogo from "../../media/logos/akkodis-logo.png";
import disLogo from "../../media/logos/dis-ag-logo.png";
import adeccoIcon from "../../media/logos/adecco.png";
import modisIcon from "../../media/logos/modis.png";
import proservIcon from "../../media/logos/proserv.png";
import proservLogo from "../../media/logos/proserv-logo.png";
import disIcon from "../../media/logos/dis.png";
import ImpressumPage from "./impressumPage";
import Routes from "../../common/routing/routes";

export interface Props {
    user: UserInfoListItem | undefined;
    department: DepartmentsList | undefined;
}
export const ApplicationPage = (props: Props) => {

    const [aside, setAside] = useState("off");

    const brand = props.user?.employee.brand.toLowerCase();
    const favIcon = document.getElementById("favicon") as HTMLLinkElement;

    let logo = "";
    let icon = "";
    let impressum = "";
    if (brand == "modis" || brand == "akkodis") {
        logo = modisLogo;
        icon = modisIcon;
        impressum = Routes.IMPRESSUM_MODIS;
    } else if (brand == "dis") {
        logo = disLogo;
        icon = disIcon;
        impressum = Routes.IMPRESSUM_DIS;
    }
    else if (brand == "proserv") {
        logo = proservLogo;
        icon = proservIcon;
        impressum = Routes.IMPRESSUM_PROSERV;
    } else {
        logo = adeccoLogo;
        icon = adeccoIcon;
        impressum = Routes.IMPRESSUM_ADECCO
    }

    favIcon.href = icon;

    return <>
        <BrowserRouter>
            <div id="kt_header_mobile" className={`header-mobile align-items-center header-mobile-fixed ${brand == 'akkodis' ? 'modis' : brand}`}>
                <a href="index.html">
                    <img alt="Logo" src={logo} />
                </a>

                <div className="d-flex align-items-center">
                    <button className="btn p-0 burger-icon burger-icon-left mobile-toggle" id="kt_aside_mobile_toggle" onClick={() => { if (aside == "on") { setAside("off"); } else { setAside("on"); } }}  >
                        <span></span>
                    </button>
                </div>
            </div>

            <div id="kt_header" className={`header header-fixed ${brand == 'akkodis' ? 'modis' : brand}`}>

                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    <div></div>
                    <div className="topbar">
                        <div className="topbar-item">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hallo,</span>
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{`${props.user?.employee.firstname} ${props.user?.employee.lastname}`}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`d-flex flex-column flex-root ${props.user?.employee.brand.toLowerCase() == 'akkodis' ? 'modis' : props.user?.employee.brand.toLowerCase()}`}>

                <div className="d-flex flex-row flex-column-fluid page">

                    <Navigation logo={logo} aside={aside} department={props.department}></Navigation>

                    <div className="d-flex flex-column flex-row-fluid wrapper">

                        <Switch>
                            <Route path="/key/:id" component={() => <ContentPage title={`Willkommen ${props.user?.employee.firstname} ${props.user?.employee.lastname},`} text={`auf dieser Seite sind alle wesentlichen Dokumente zum Arbeitsverhältnis zu finden.`} brand={`${brand}`}></ContentPage>} />
                            <Route path="/welcome" component={() => <ContentPage title={`Willkommen ${props.user?.employee.firstname} ${props.user?.employee.lastname},`} text={`auf dieser Seite sind alle wesentlichen Dokumente zum Arbeitsverhältnis zu finden.`} brand={`${brand}`}></ContentPage>} />
                            <Route path="/impressum" component={() => <ImpressumPage title="Impressum"></ImpressumPage>} />
                            <Route path="/category" render={({ match: { path } }) => (
                                <Switch>
                                    <Route path={`${path}/did/:did/cid/:cid`} component={() => <DocumentPage title="Dokumente" department={props.department}></DocumentPage>} />

                                    <Redirect exact from={path + "/*"} to={path} />
                                </Switch>
                            )}>
                            </Route>
                        </Switch>

                        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
                            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">

                                <div className="text-dark order-2 order-md-1">
                                    <span className="text-muted font-weight-bold mr-2">{(new Date()).getFullYear()}©</span>
                                    <a href={Routes.AGH_HOMEPAGE} target="_blank" className="text-dark-75 text-hover-primary">AGH</a>
                                </div>

                                <div className="nav nav-dark">
                                    <a href={impressum} target="_blank" className="nav-link pl-0 pr-5">Impressum</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </BrowserRouter>

    </>
}

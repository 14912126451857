import React from 'react';
import { Link } from 'react-router-dom';
import { Category, Department } from '../../common/navigation/types';

export interface Props {
    department: Department;
    open: boolean;
    icon: JSX.Element;
    selectedCategory: Category | undefined;
    onClick: () => void;
    onCategoryClick: (category: Category) => void;
}

const DocumentNavigationItem = (props: Props) => {
    return <li key={props.department.departmentId} className={`menu-item menu-item-submenu ${props.open && 'menu-item-open'}`} >
        <a className="menu-link menu-toggle" onClick={props.onClick}>
            <span className="svg-icon menu-icon">
                {props.icon}
            </span>
            <span className="menu-text">{props.department.name}</span>
            <i className="menu-arrow"></i>
        </a>
        <div className="menu-submenu">
            <ul className="menu-subnav">
                {props.department.categories.map((category, i) => {
                    return (
                        <li key={category.categoryId} className={`menu-item ${category === props.selectedCategory && "menu-item-active"}`} aria-haspopup="true" onClick={() => props.onCategoryClick(category)}>
                            <Link to={`/category/did/${props.department.departmentId}/cid/${category.categoryId}`} className="menu-link">
                                <i className="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <span className="menu-text">{category.name}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    </li>
}

export default DocumentNavigationItem;
import './unauthorized.css';

export const UnauthorizedPage = () => {
    return <>
        <div className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center">

            <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                <h1 className="error-title text-stroke text-transparent">401</h1>
                <p className="display-4 font-weight-boldest text-white mb-12">Wie kommen Sie hier her?</p>
                <p className="font-size-h1 font-weight-boldest text-dark-75">Entschuldigung, aber Sie sind nicht berechtigt diesen Bereich zu sehen.</p>
                <p className="font-size-h4 line-height-md">Bitte gehen Sie wieder zurück.</p>
            </div>
        </div>
    </>
}